<template>

  <div class="home" ref="total">
    <!---导航-->
    <div class="nav2" :class="isMourn == 'Y' ? 'filter' : ''">
      <div>
        <div class="img-list" v-if="language == 'zh'">
          <div class="img-item" v-for="(item, index) in navList" :key="index">
            <el-link @click="goToElement(item.name)" :underline="false">
              <div class="img-wrap">
                <img :src="item.url" />
              </div>
              <div class="text-wrap"><span>{{ item.text }}</span></div>
            </el-link>
          </div>
        </div>
        <div class="img-list" v-if="language == 'en'">
          <div class="img-item" v-for="(item, index) in navList2" :key="index">
            <el-link @click="goToElement(item.name)" :underline="false">
              <div class="img-wrap">
                <img :src="item.url" />
              </div>
              <div class="text-wrap"><span>{{ item.text }}</span></div>
            </el-link>
          </div>
        </div>
        <Menu></Menu>
      </div>
    </div>
    <!--轮播图-->
    <div class="slider" id="carousel" v-if="isCarousel" :class="isMourn == 'Y' ? 'filter' : ''">
      <div class="SwiperBox" @mouseenter="MouseFun('移入')" @mouseleave="MouseFun('移出')">
        <!-- 图片 -->
        <img :class="['imgCss', ShowImg == index ? 'ShowCss' : '']" :src="item.filePath"
          v-for="(item, index) in imgList" :key="index" @click="goLink(item)" />
        <!-- 左箭头按钮 -->
        <div class="leftBtn" @click="throttle(PrevFun)">
          <img src="../assets/images/leftSlider.png" class="leftPic" />
        </div>
        <!-- 右箭头按钮 -->
        <div class="rightBtn" @click="throttle(NextFun)">
          <img src="../assets/images/rightSlider.png" class="leftPic" />
        </div>
        <!-- 下方指示点容器 -->
        <div class="instBox">
          <div v-for="(item, index) in imgList.length" :key="index" @click="toggleSlider(index)"
            :class="['inst', ShowImg == index ? 'instActv' : '']">
          </div>
        </div>
      </div>

      <!--语言切换-->
      <div class="Language" :class="isMourn == 'Y' ? 'filter' : ''">
        <p class="languageName" :class="{ 'orangeBg': isChange }" @click="changeLan('zh')">中</p>
        <p class="languageName" :class="{ 'orangeBg': !isChange }" @click="changeLan('en')">EN</p>
      </div>
    </div>

    <!--研学介绍-->
    <div class="research" id="data" v-if="isData" :class="isMourn == 'Y' ? 'filter' : ''">
      <div class="researchTitle">
        {{ studyInfo.dataTitle }}
      </div>
      <div class="researchContent">
        <ul>
          <li>
            <h4>
              <countTo :startVal='startVal' :endVal='studyInfo.dataA' :duration='3000'></countTo>
            </h4>
            <p>{{ studyInfo.dataAContent }}</p>
          </li>
          <li>
            <h4>
              <countTo :startVal='startVal' :endVal='studyInfo.dataB' :duration='3000'></countTo>
            </h4>
            <p>{{ studyInfo.dataBContent }}</p>
          </li>
          <li>
            <h4>
              <countTo :startVal='startVal' :endVal='studyInfo.dataC' :duration='3000'></countTo>
            </h4>
            <p>{{ studyInfo.dataCContent }}</p>
          </li>
          <li>
            <h4>
              <countTo :startVal='startVal' :endVal='studyInfo.dataD' :duration='3000'></countTo>
            </h4>
            <p>{{ studyInfo.dataDContent }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!--研学娱乐-->
    <div :class="isMourn == 'Y' ? 'filter-wrap' : ''">
      <div class="study" :class="{ 'activeBg': isActive }" id="study" v-if="isStudy">
        <div class="reTitle">
          <p v-if="language == 'zh'" class="name" :class="{ 'orange': isActive }" @click="toggleStyle(true, '1')">研学</p>
          <p v-if="language == 'en'" class="name" :class="{ 'orange': isActive }" @click="toggleStyle(true, '1')">Study
            tour
          </p>
          <div class="line" :class="{ 'activeLine': isActive }"></div>
          <p v-if="language == 'zh'" class="name" :class="{ 'orange': !isActive }" @click="toggleStyle(false, '2')">娱乐</p>
          <p v-if="language == 'en'" class="name" :class="{ 'orange': !isActive }" @click="toggleStyle(false, '2')">
            Entertainment</p>
        </div>
        <div class="studyContent">
          <div class="contentLeft">
            <div v-if="isActive">
              <h4 v-if="language == 'zh'">为何选择来皓月研学~</h4>
              <h4 v-if="language == 'en'">Why do you choose to have a study tour in Haoyue?</h4>
            </div>
            <div v-if="!isActive">
              <h4 v-if="language == 'zh'">来皓月怎么玩~</h4>
              <h4 v-if="language == 'en'">How to play in Haoyue?</h4>
            </div>
            <div>
              <div class="numberList" v-if="numberList.length > 1">
                <div class="one" v-for="(item, index) in numberList" :class="[curIndex == index ? 'activeIndex' : '']"
                  :key="index" @click="handleNumber(item, index)">
                  {{ item.learnTopic }}</div>
              </div>
              <div class="numberList" v-if="numberList.length == 1">
                <p class="line2"></p>
              </div>
            </div>
            <p>{{ numberData }}</p>
          </div>
          <div class="contentRight2">
            <div class="guide animation-btn" @click="goGuide">
              <span v-if="language == 'zh'">{{ isActive ? "研学手册" : "娱乐指南" }} <i class="el-icon-arrow-right"></i></span>
              <span v-if="language == 'en'">{{ isActive ? "Tour manual" : "Guide" }} <i
                  class="el-icon-arrow-right"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--活动资讯-->
    <div class="eventNews" id="consult" v-if="isConsult" :class="isMourn == 'Y' ? 'filter-wrap' : ''">
      <div class="eventContent">
        <h3 v-if="language == 'zh'">活动资讯</h3>
        <h3 v-if="language == 'en'">Activity information</h3>
        <div class="newsList">
          <div class="newsItem" v-for="(item, index) in list" :key="index">
            <activityItem :item="item" :index="index" openType="blank" />
          </div>
        </div>
        <div v-if="language == 'zh'" class="more" @click="goMore">了解更多 <i class="el-icon-arrow-right yFont"></i></div>
        <div v-if="language == 'en'" class="more" @click="goMore">More details <i class="el-icon-arrow-right yFont"></i>
        </div>
      </div>
    </div>

    <!--景点切换-->
    <div class="scenic" id="scenic" v-if="isScenic" :class="isMourn == 'Y' ? 'filter-wrap' : ''">
      <div class="scenicItem" v-for="(item, index) in scenicList" :key="index">
        <ul class="tabTitle">
          <li @click="check(index2)" v-for="(item2, index2) in tabLilst" :key="index2"
            :class="[cur == index2 ? 'active' : '']"
            :style="{ backgroundImage: cur == index2 ? 'url(' + item2.filePath + ')' : '' }">
            <span>{{ item2.scenicTitle }}</span>
            <div class="liMask" v-if="cur == index2"></div>
          </li>
          <li v-if="language == 'zh'" @click="check2" class="viewMore" :class="[viewMore ? 'active2' : '']">更多景点<i
              class="el-icon-arrow-right nFont"></i></li>
          <li v-if="language == 'en'" @click="check2" class="viewMore" :class="[viewMore ? 'active2' : '']">More scenic spots
            <i class="el-icon-arrow-right nFont"></i></li>
        </ul>

        <div v-if="cur == index">
          <div class="tabContent">
            <img :src="item.filePath">
          </div>
          <div class="tabFooter">
            <div class="tabInfo">
              <div class="tabItem">
                <h4>{{ item.scenicTitle }}</h4>
                <p>{{ item.scenicDesc }}</p>
              </div>
              <div class="contentRight">
                <div v-if="language == 'zh'" class="guide nFlex animation-btn" @click="goDiscover(item.id)">
                  探索发现 <i class="el-icon-arrow-right"></i>
                </div>
                <div v-if="language == 'en'" class="guide nFlex animation-btn" @click="goDiscover(item.id)">
                  Exploring <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--招商入驻-->
    <div class="attract" id="attract" v-if="isAttract" :class="isMourn == 'Y' ? 'filter' : ''">
      <div class="attractBox">
        <div class="attractTitle">
          <img src="../assets/images/star.png" />
          <p v-if="language == 'zh'">招商入驻</p>
          <p class="enAttract" v-if="language == 'en'">Merchandise to settle in</p>
          <img src="../assets/images/star.png" />
        </div>
        <div class="attractContent">
          <ul>
            <li v-if="language == 'zh'">
              <h4>属性</h4>
              <p>餐饮/休闲/娱乐/文化/教育</p>
            </li>
            <li v-if="language == 'en'">
              <h4>Attribute</h4>
              <p>Catering/Leisure/Entertainment/Culture/Education</p>
            </li>
            <li v-if="language == 'zh'">
              <h4>功能</h4>
              <p>网红打卡/创意零售/地方特色</p>
            </li>
            <li v-if="language == 'en'">
              <h4>Function</h4>
              <p>Internet-famous spot/Creative retailing/local characteristic</p>
            </li>
            <li v-if="language == 'zh'">
              <h4>优势</h4>
              <p>皓月专供/品牌推广/商铺优惠</p>
            </li>
            <li v-if="language == 'en'">
              <h4> Advantage</h4>
              <p>Haoyue Provide exclusively/Brand promotion/Store discount</p>
            </li>
          </ul>
          <div class="consult">
            <h5 v-if="language == 'zh'">招商咨询 <img class="consultPhoto" src="../assets/images/tel.png" /></h5>
            <h5 v-if="language == 'en'">Merchant consultation<img class="consultPhoto" src="../assets/images/tel.png" />
            </h5>
            <p>18244087381</p>
            <p>18686549586</p>
          </div>
        </div>
      </div>
    </div>

    <!--合作伙伴-->
    <div class="buddy" id="buddy" v-if="isBuddy" :class="isMourn == 'Y' ? 'filter' : ''">
      <div class="buddyBox">
        <div v-if="language == 'zh'" class="buddyTitle">我们的合作小伙伴</div>
        <div v-if="language == 'en'" class="buddyTitle">Our partner</div>
        <ul>
          <li v-for="(item, index) in buddyList" :key="index">
            <el-link :href="item.partnerUrl" :underline="false" target="_blank">
              <img :src="item.filePath" class="buddyPhoto" />
            </el-link>
          </li>
        </ul>
        <p v-if="language == 'zh'">部分合作品牌</p>
        <p v-if="language == 'en'">Partly cooperative brands</p>
      </div>
    </div>

    <!--地图-->
    <div id="container" class="amap" :class="isMourn == 'Y' ? 'filter' : ''">
    </div>

    <div class="newMask"></div>
    <div class="newMask2"></div>

    <!--意见反馈-->
    <div>
      <div class="feedback" :class="isMourn == 'Y' ? 'filter-wrap' : ''">
        <div class="feedbackLeft" v-if="language == 'zh'">
          <h4>意见反馈：</h4>
          <el-input class="uInput" v-model.trim="inputContent" maxlength="500" show-word-limit
            placeholder="感谢浏览皓月工旅研学官网，欢迎您对我们提出宝贵建议~"></el-input>
        </div>
        <div class="feedbackLeft" v-if="language == 'en'">
          <h4>Feedback：</h4>
          <el-input class="uInput" v-model.trim="inputContent" maxlength="500" show-word-limit
            placeholder="We value your suggestions."></el-input>
        </div>
        <div class="feedbackRight" @click="feedback" :class="isMourn == 'Y' ? 'filter-wrap' : ''">
          {{ language == 'en' ? 'Submit' : '提交' }}
        </div>
      </div>
    </div>
    <Message ref="msgtext"></Message>
  </div>
</template>

<script>
// @ is an alias to /src

import countTo from 'vue-count-to';
import activityItem from '../components/activityItem.vue';
import AMapLoader from '@amap/amap-jsapi-loader'
import { getBuddy, getConsult, getStudy, getStudyList, feedbackAdd, getScenicList, queryMenu, querySlider } from "@/api/home";
import Menu from '@/components/homeNav.vue'
import Message from '@/components/message.vue'
export default {
  name: 'HomeView',
  components: {
    countTo,
    activityItem,
    Menu,
    Message
  },
  data() {
    return {
      startVal: 0,
      endVal: 100,
      isActive: true, //研学娱乐切换状态
      isChange: true, //中英文切换状态
      list: [],
      tabTitle: ['欢乐荟', '百合园', '清真寺', '还有更多 >'],
      tabPhoto: require("@/assets/images/scenic1.png"),
      tabMain: [
        require("@/assets/images/scenic1.png"),
        require("@/assets/images/photo.jpg"),
        require("@/assets/images/scenic1.png"),
      ],
      cur: 0, //默认选中第一个tab
      map: null,//地图对象
      marker: null,//标记对象
      loaded: false,
      infoWindow: null,//弹窗对象
      webSecret: "c49455b2a1cd4f9229ebc4aa6701800a",
      inputContent: "",
      imgList: [],
      ShowImg: 0,  // 表示当前显示的图片
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
      buddyList: [], //合作伙伴列表
      ismake: false,
      navList: [
        { url: require('../assets/images/nav-1.png'), text: '轮播图', name: "carousel" },
        { url: require('../assets/images/nav-2.png'), text: '数据展示', name: "data" },
        { url: require('../assets/images/nav-3.png'), text: '研学娱乐', name: "study" },
        { url: require('../assets/images/nav-4.png'), text: '活动资讯', name: "consult" },
        { url: require('../assets/images/nav-5.png'), text: '景点展示', name: "scenic" },
        { url: require('../assets/images/nav-6.png'), text: '招商入驻', name: "attract" },
        { url: require('../assets/images/nav-7.png'), text: '合作伙伴', name: "buddy" }
      ],
      navList2: [
        { url: require('../assets/images/nav-1.png'), text: 'Homepage', name: "carousel" },
        { url: require('../assets/images/nav-2.png'), text: 'Data presentation', name: "data" },
        { url: require('../assets/images/nav-3.png'), text: 'Study tour and entertainment', name: "study" },
        { url: require('../assets/images/nav-4.png'), text: 'Activity information', name: "consult" },
        { url: require('../assets/images/nav-5.png'), text: 'Scenic spot display', name: "scenic" },
        { url: require('../assets/images/nav-6.png'), text: 'Merchandise to settle in', name: "attract" },
        { url: require('../assets/images/nav-7.png'), text: 'Cooperative partners', name: "buddy" }
      ],
      menuList: [],//权限菜单
      info: {
        name: "",
        phone: ""
      },
      studyInfo: {

      },
      current: 0,
      learn_type: "1", //研学娱乐
      numberList: [],
      numberData: "",
      routerId: null,
      scenicList: [], //景点列表
      tabLilst: [],
      isToast: false,
      msgText: "提交成功",
      isCarousel: true,// 轮播图
      isData: true, // 数据展示
      isStudy: true,// 研学娱乐
      isConsult: true, //活动咨询
      isScenic: true, //景点展示
      isAttract: true, //招商入驻
      isBuddy: true, //合作伙伴
      sliderId: null, //轮播图ID
      sliderPath: "", //轮播图外链地址
      isLink: null, //1：外部链接，2：内部链接，3：显示内容
      linkType: null, //1：景点，2：活动，3：研学，4：娱乐
      newId: null,
      viewMore: false,
      curIndex: 0, //圆圈样式
      scrollHeight: 0,
      newInfo: {},
      language: ""
    }
  },
  mounted() {
    this.initMap()//加载地图
    this.getHome()//获取合作伙伴
    this.getNews()//获取活动资讯
    this.getStudyListData() // 获取研学娱乐
    this.getScenic()//获取景点列表
    this.getMenu() //获取菜单权限
    this.getSwiper() //获取轮播图
    this.setTimeoFun()//轮播图
    this.getStudyData() //获取研学娱乐数据
    window.addEventListener("scroll", this.handleScroll);
    this.language = localStorage.getItem("language")
    if (this.language == "zh") {
      this.isChange = true
    } else if (this.language == "en") {
      this.isChange = false
    }

  },
  computed: {
    isMourn() {
      return this.$store.getters.getPageMourn;
    }
  },
  methods: {
    //切换研学娱乐样式
    toggleStyle(type, number) {
      if (this.isActive == type) return
      this.curIndex = 0
      this.isActive = type;
      this.numberList = []
      this.numberData = ""
      this.learn_type = number
      this.getStudyListData()
    },

    goMore() {
      //this.$router.push('/activity')

      const url = this.$router.resolve({ path: '/activity' }).href;
      window.open(url, '_blank');
    },

    //切换选项卡
    check(index) {

      this.cur = index
      this.tabPhoto = this.tabMain[index]
      this.viewMore = false

    },


    //初始化地图
    async initMap() {
      await AMapLoader.load({
        key: "55ee061e8b48964ca422abbd3f46da16", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.LngLat'],  // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      });

      this.map = new window.AMap.Map("container", {//生成底图在mapContainer这个div里
        center: [125.162304, 43.892059], // 初始中心点坐标
        zoom: 15 // 初始缩放级别
      });

      //1.创建Marker对象
      let marker = new window.AMap.Marker({
        position: [125.162304, 43.892059],//要展示marker的经度、纬度。数据格式就是数组里放入经纬度数据
        icon: require("../assets/images/postion.png"), //显示的图标
        offset: [-16, -32],//图标偏移量，展示时会默认以图标的左上角为原点，不设置偏移量会导致地图放大缩小时造成图标偏移的情况，偏移量设置为图标宽度的负一半，负整个高度。
      });

      this.map.add(marker); //添加marker
    },

    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun(type) {// 停止定时器            // 重新执行定时器
      type == '移入' ? clearTimeout(this.start) : this.setTimeoFun()
    },
    setTimeoFun() {
      this.start = setInterval(() => {
        this.NextFun()
      }, 5000)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle(fun) {
      if (this.flag) {
        this.flag = false;
        fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true;
        }, 100); // 节流间隔时间
      }
    },
    // 上一张
    PrevFun() {
      if (this.ShowImg !== 0) {
        this.ShowImg--
        this.clickSlider(this.ShowImg)

      } else {
        this.ShowImg = this.imgList.length - 1
        this.clickSlider(this.ShowImg)
      }
    },
    // 下一张
    NextFun() {
      if (this.ShowImg !== this.imgList.length - 1) {
        this.ShowImg++
        this.clickSlider(this.ShowImg)

      } else {
        this.ShowImg = 0
        this.clickSlider(this.ShowImg)
      }
    },

    //切换轮播指示点
    toggleSlider(index) {
      this.ShowImg = index
      this.clickSlider(this.ShowImg)
    },

    clickSlider(ShowImg) {
      this.sliderId = this.imgList[ShowImg].typeId
      this.sliderPath = this.imgList[ShowImg].carouselUrl
      this.isLink = this.imgList[ShowImg].isLink
      this.linkType = this.imgList[ShowImg].linkType
      this.newId = this.imgList[ShowImg].id

    },
    changeLan(type) {
      this.isChange = !this.isChange
      localStorage.setItem('language', type)
      this.language = type

      window.location.reload();

    },
    goGuide() {
      const url = this.$router.resolve({
        path: '/further',
        query: {
          id: this.routerId,
          total: this.numberList.length,
          learn_type: this.learn_type
        }
      }).href;
      window.open(url, '_blank');
    },

    //合作伙伴接口
    getHome() {
      getBuddy().then(res => {
        this.buddyList = res.data.splice(0, 8)
      })
    },
    goToElement(id) {
      window.location.hash = `#${id}`;
    },

    //获取活动资讯
    getNews() {
      getConsult().then(res => {
        this.list = res.data.splice(0, 3)
      })
    },

    //获取研学娱乐数据
    getStudyData() {
      getStudy().then(res => {
        this.studyInfo = res.data
        this.newInfo = res.data
      })
    },
    changeType(index) {
      this.current = index
    },

    //获取研学娱乐列表
    getStudyListData() {
      getStudyList({
        learn_type: this.learn_type
      }).then(res => {
        this.numberList = res.data
        if (this.numberList.length > 0) {
          this.numberData = this.numberList[0].learnTitle
          this.routerId = this.numberList[0].id
        }
      })
    },

    //切换路线
    handleNumber(item, index) {
      this.numberData = this.numberList[index].learnTitle
      this.routerId = item.id
      this.curIndex = index
    },

    //获取景点列表选项卡
    getScenic() {
      getScenicList().then(res => {
        this.scenicList = res.data

        let list = res.data;
        this.tabLilst = list;
      })
    },

    //提交意见反馈
    feedback() {
      if (this.inputContent == "") {
        if(this.language == "zh"){
          this.$message.error('请输入反馈信息！');
        }else if(this.language == "en"){
          this.$message.error('Please provide feedback.');
        }
        
        return false
      } else {
        feedbackAdd({
          source: "1",
          feedbackContent: this.inputContent
        }).then(res => {

          if (this.language == "zh") {
            this.$refs.msgtext.show("提交成功" )
          } else if (this.language == "en") {
            this.$refs.msgtext.show("Submit successfully")
          }

          this.inputContent = ""
        })
      }

    },

    //获取菜单权限
    getMenu() {
      queryMenu().then(res => {
        this.menuList = res.data
        let that = this

        let uList = this.navList.filter(item1 => this.menuList.some(item2 => item2.name == item1.name));
        this.navList = uList

        let nameList = this.menuList.map(item => {
          return item.name
        })

        this.isCarousel = nameList.includes("carousel")
        this.isData = nameList.includes("data")
        this.isStudy = nameList.includes("study")
        this.isConsult = nameList.includes("consult")
        this.isScenic = nameList.includes("scenic")
        this.isAttract = nameList.includes("attract")
        this.isBuddy = nameList.includes("buddy")

      })
    },

    //获取轮播图
    getSwiper() {
      querySlider().then(res => {
        this.imgList = res.data

        //轮播图初始跳转状态
        this.clickSlider(0)
      })
    },

    //调整链接
    goLink(item) {
      if (this.isLink == "1") {

        //alert('123')
        window.open(this.sliderPath, '_blank');


      } else if (this.isLink == "2") {
        let paths = ['', '/ScenicDetails?id=', '/activityDetail?id=', '/further?learn_type=1&id=', '/further?learn_type=2&id='];
        if (!paths[this.linkType]) return;

        const url = this.$router.resolve({
          path: `${paths[this.linkType]}${this.sliderId}`,
        }).href;
        window.open(url, '_blank');
      } else if (this.isLink == "3") {
        // this.$router.push({
        //   path: '/displayContent',
        //   query: {
        //     id: this.newId
        //   }
        // })
        const url = this.$router.resolve({
          path: '/displayContent',
          query: {
            id: this.newId
          }
        }).href;
        window.open(url, '_blank');
      }

    },

    goDiscover(id) {
      const url = this.$router.resolve({
        path: '/ScenicDetails',
        query: {
          id: id,
        }
      }).href;
      window.open(url, '_blank');

    },
    check2() {
      this.viewMore = true
      const url = this.$router.resolve({
        path: '/ScenicList',
      }).href;
      window.open(url, '_blank');
    },
    //监听页面滚动距离
    handleScroll() {
      let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
      //console.log(top);
      if (top >= 650 && top <= 750) {
        this.studyInfo = this.newInfo
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.filter,
.filter-wrap {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}

.slider {
  width: 100%;
  height: 920px;
  margin: 0;
  position: relative;
}

.research {
  width: 100%;
  height: 640px;
  background-image: url("../assets/images/researchBg.png");
}

.researchTitle {
  width: 1100px;
  margin: 0 auto;
  font-size: 40px;
  font-family: "SourceHan";
  //font-weight: bold;
  color: #343434;
  padding: 120px 0 0 0;
  text-align: center;
}

.researchContent {
  width: 1100px;
  margin: 120px auto 0;

}

.researchContent ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.researchContent ul li {
  width: 20%;
  margin: 0 0 0 0;
  text-align: center;
}

.researchContent ul li h4 {
  font-size: 40px;
  line-height: 54px;
  color: #FF7E14;
  margin: 0;
}

.researchContent ul li p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #343434;
  word-wrap: break-word;
  /* 允许在单词内换行 */
  overflow-wrap: break-word;
}

.study {
  width: 100%;
  height: 960px;
  margin: 0 auto;
  background-image: url("../assets/images/photo5.jpg");
  background-size: 100% 100%;
  position: relative;
}

.activeBg {
  background-image: url("../assets/images/photo4.jpg");
  background-size: 100% 100%;
}

.study img {
  width: 100%;
  height: 960px;
}

.reTitle {
  width: 1320px;
  margin: 0 auto;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reTitle .name {
  font-size: 40px;
  font-family: "SourceHan";
  cursor: pointer;
  color: #ffffff;
}

.line {
  flex: 1;
  margin: 0 10px 0 20px;
  height: 1px;
  background: linear-gradient(270deg, #FF7E14 0%, rgba(255, 126, 20, 0) 100%);
}

.activeLine {
  flex: 1;
  margin: 0 10px 0 20px;
  height: 1px;
  background: linear-gradient(270deg, rgba(255, 126, 20, 0) 0%, #FF7E14 100%);

}

.orange {
  color: #FF7E14 !important;
}

.studyContent {
  width: 1320px;
  margin: 400px auto 0;
  display: flex;
  justify-content: space-between;

}

.contentLeft h4 {
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

.numberList {
  display: flex;
  align-items: center;
}

.numberList div {
  width: 48px;
  height: 48px;
  margin: 0 0 0 0;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px 0 0;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

.numberList div:hover {
  background: #FF7E14;
  z-index: 20;
}

.contentLeft p {
  font-size: 24px;
  color: #FFFFFF;
}

.guide {
  width: 148px;
  height: 48px;
  background: #FF7E14;
  border-radius: 28px;
  font-size: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.guide span {
  cursor: pointer;
  display: flex;
  align-items: center;
}

// .guide:hover {
//   border: 4px solid rgba(255, 255, 255, .3)
// }

.eventNews {
  width: 100%;
  height: 838px;
  background: #F5F5F5;
  margin: 0 auto;
}

.eventContent {
  width: 1320px;
  margin: 0 auto;
  padding: 96px 0 0 0;
}

.eventContent h3 {
  font-size: 40px;
  font-family: "SourceHan";
  text-align: center;
  margin: 0 0 56px 0;
}

.newsList {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.newsItem {
  width: 33.3%;
}

.more {
  width: 148px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #FF7E14;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 56px auto;
  cursor: pointer;
}

.scenic {
  width: 100%;
  height: 900px;
  margin: 0 auto;
  position: relative;
}

.tabTitle {
  width: 1320px;
  margin: 0px auto 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -31px;
  left: 50%;
  margin-left: -690px;
  z-index: 20;
}

.tabTitle li {
  float: left;
  width: 312px;
  height: 120px;
  border-radius: 16px;
  padding: 10px 0;
  text-align: center;
  background-color: #FF7E14;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: SourceHan;
  color: #ffffff;
  font-weight: 500;
  position: relative;
}

.tabTitle li span {
  width: 80%;
  position: absolute;
  //top: 0px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;
  font-family: "SourceHan";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.liMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.6)
}

/*点击对应的标题添加对应的背景颜色*/
.tabTitle .active {
  //background-color: rgba(0, 0, 0, 0.6);
  background-size: 100% 100%;
  color: #ffffff;
}

.tabContent {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 50%;
  margin-left: -50%;
  z-index: 0;
}

.tabContent img {
  width: 100%;
  height: 900px;
  margin: 0;
  padding: 0;
}

.tabFooter {
  width: 100%;
  height: 204px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tabInfo {
  width: 1320px;
  padding: 20px 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.tabItem {
  width: 950px;
  margin: 0 0 0 0;
}

.tabItem h4 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin: 10px 0 25px 0;
  word-break: break-all;
}

.tabItem p {
  font-size: 18px;
  color: #ffffff;
  line-height: 34px;
  word-break: break-all;
}

.attract {
  width: 100%;
  height: 720px;
  margin: 0 auto;
  background-image: url("../assets/images/attract.png");
}

.attractBox {
  width: 1320px;
  margin: 0 auto;
  padding: 96px auto 0;
}

.attractTitle {
  max-width: 578px;
  margin: 0 auto;
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attractTitle p {
  font-size: 40px;
  font-family: "SourceHan";
  color: #ffffff;
  margin: 0 0 0 0;
}

.attractContent {
  width: 100%;
  margin: 80px 0 0 0;
  display: flex;
}

.attractContent ul {
  width: 1000px;
  list-style-type: none;
  margin: 38px 0 0 -0px;
  padding: 0;
  display: flex;
  align-items: center;

}

.attractContent ul li {
  width: 344px;
  height: 344px;
  border: 1px solid #888888;
  border-radius: 50%;
  margin: 0 -20px 0 0;

}

.attractContent ul li:hover {
  border: 1px solid #FF7E14;
  z-index: 20;
}

.attractContent ul li h4 {
  font-size: 40px;
  font-weight: bold;
  color: #FF7E14;
  margin: 80px 0 20px 50px
}

.attractContent ul li p {
  width: 230px;
  font-size: 24px;
  color: #ffffff;
  line-height: 36px;
  margin: 0 0 0 50px;
  word-break: break-all;
}

.consult {
  margin: 88px 0 0 67px
}

.consult h5 {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  line-height: 60px;
  margin: 10px 0 20px 0;
  display: flex;
  align-items: center;
}

.consult h5 img {
  width: 36px;
  height: 36px;
  margin: 0 0 0 10px;
}

.consult p {
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

.buddy {
  width: 100%;
  height: 716px;
  background: #f2f2f2;
  margin: 0 auto;
}

.buddyBox {
  width: 1320px;
  margin: 0 auto;
}

.buddyBox ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.buddyBox ul li {
  width: 22%;
  margin: 20px 0 0px 0;
}

.buddyBox p {
  margin: 66px auto;
  text-align: center;
  font-size: 16px;
  color: #9E9E9E;
}

.buddyTitle {
  margin: 0px auto;
  padding: 48px 0;
  font-size: 40px;
  font-family: "SourceHan";
  font-weight: bold;
  color: #343434;
  text-align: center;
}

#container {
  width: 100%;
  height: 800px;
  margin: 0 auto;
}

.amap {
  position: relative;
}

.feedback {
  width: 1320px;
  height: 148px;
  margin: 0 auto;
  position: absolute;
  bottom: -60px;
  left: 50%;
  margin-left: -660px;
  z-index: 160;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedbackLeft {
  width: 984px;
  height: 148px;
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
}

.feedbackLeft h4 {
  margin: 0 0 10px 0;
  font-size: 32px;
  font-weight: bold;
}

.feedbackRight {
  width: 312px;
  height: 148px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 16px;
  font-size: 32px;
  font-weight: bold;
  color: #FF7E14;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uInput {
  width: 98%;
  height: 40px;
  padding: 10px 0 10px 0;
  margin: 0 0 0 -15px;
  border: none;
  box-sizing: border-box;
}

.uInput .el-input__inner {
  border: none !important;
  border: 1px solid #ffffff;
  box-shadow: none !important;
}

//修改未输入时字体大小和颜色
.el-input__placeholder {
  font-size: 24px;
  /* 修改为需要的字号 */
}

.feedbackLeft /deep/ .el-input__inner {
  border: 1px solid #ffffff;
}

.el-input /deep/ .el-input__inner::placeholder {
  color: #9E9E9E;
  font-size: 24px;
  font-weight: 200;
}

.buddyPhoto {
  width: 312px;
  height: 200px;
  transition: all .1s linear;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    transform: scale(1.1);
  }
}


/* 图片容器样式 */
.SwiperBox {
  position: relative;
  width: 100%;
  height: 920px;
  box-sizing: border-box;
  cursor: pointer;
}

/* 图片默认样式 */
.imgCss {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 920px;
  opacity: 0;
  transition: 0.8s;
  /* 淡入淡出过渡时间 */
}

/* 图片选中样式(继承上方默认样式) */
.ShowCss {
  opacity: 1;
}

/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn {
  position: absolute;
  bottom: 20px;
  right: 18%;
  z-index: 500;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0.6;

}

.rightBtn {
  position: absolute;
  bottom: 20px;
  right: 16%;
  z-index: 500;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0.6;

}


/* 下方指示器盒子 */
.instBox {
  position: absolute;
  left: 18%;
  transform: translateX(-50%);
  bottom: 24px;
  display: flex;
}

/* 小圆点 */
.inst {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: #cccccc;
  border-radius: 50%;
  cursor: pointer;
}

.inst:last-child {
  margin-right: 0px;
}

.instActv {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 4px solid #FF7E14;
  margin-top: -4px;
}

.leftPic {
  width: 32px;
  height: 32px;
}

.Language {
  width: 80px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  position: absolute;
  top: 48px;
  right: 1.3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
  box-sizing: border-box;
}

.languageName {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.orangeBg {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background: #FF7E14;
}

.consultPhoto:hover {
  &:hover {
    transform: scale(1.1);
  }
}


.nav2 {
  position: fixed;
  top: 108px;
  right: 30px;
  z-index: 250;
  width: 80px;
  padding: 8px;
  box-sizing: border-box;
}

.img-list {
  width: 80px;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.img-item {
  position: relative;
  margin: 0 0 15px 0;

  &:hover .text-wrap {
    width: auto;
    opacity: 1;
    padding-right: 82px;
  }

  &:hover .img-wrap {
    background: #FF7E14;
  }
}

.img-wrap {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;

  img {
    width: 40px;
    height: 40px;
  }

  &.last:hover {
    background: #FFFFFF;
  }
}

.text-wrap {
  box-sizing: border-box;
  position: absolute;
  height: 68px;
  line-height: 68px;
  font-size: 20px;
  color: #FF7E14;
  background: #FFFFFF;
  border-radius: 34px;
  top: -2px;
  right: -2px;
  z-index: 7;
  width: 0px;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.2s linear;

  span {
    padding-left: 24px;
    cursor: pointer;
  }
}

.mark-wrap {
  width: 100vw;
  height: 100vh;
  min-width: 1366px;
  position: fixed;
  top: 0px;
  right: -18px;
  background: rgba(0, 0, 0, 0.3);
}

.makeon-wrap {
  position: absolute;
  width: 520px;
  height: 710px;
  background-image: url("../assets/images/appointmentBg.png");
  background-color: #ffffff;
  border-radius: 16px;
  top: 46px;
  right: 136px;
  z-index: 200;
  padding: 16px 32px;
  box-sizing: border-box;
}

.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px dashed #E5E5E5;
  margin-bottom: 24px;

  img {
    width: 257px;
    height: 40px;
  }

  .close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.item {
  margin-bottom: 32px;
}

.group-wrap {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.group {
  width: 180px;
  height: 70px;
  padding: 5px;
  border-radius: 8px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  cursor: pointer;

  p {
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    margin: 0;

    &.ren {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.active {
    background: #FFF7F1;

    p {
      color: #FF7E14;
    }
  }
}

.recr-wrap {
  display: flex;
  align-items: center;
}

.group {
  margin-right: 12px;
  height: 56px;
  color: #333333;

  &.activeItem {
    background: #FFF7F1;
    color: #FF7E14;
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #343434;
  margin-bottom: 16px;
  margin-left: 16px;
  position: relative;

  &::before {
    content: "*";
    position: absolute;
    left: -12px;
    top: 4px;
    font-size: 18px;
    color: #FF4949;
  }
}

.btn {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 24px;
  line-height: 64px;
  text-align: center;
  width: 564px;
  height: 64px;
  border-radius: 32px;
  background: #FF7E14;
  cursor: pointer;
}

/deep/ .el-input__inner {
  font-family: Source Han Sans CN;
  line-height: 56px;
  height: 56px;
  border-radius: 8px;
  color: #343434;
  font-size: 20px;
}

.lastItem {
  width: 80px;
  height: 96px;
  border-radius: 20px;
  background: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lastItem img {
  width: 40px;
  height: 40px;
  margin: 0px 0 8px 0;
}

.lastItem p {
  font-size: 20px;
  color: #FF7E14;
  margin: 0;
}

.line2 {
  width: 48px;
  height: 8px;
  border-radius: 4px;
  background: #FF7E14;
  margin: 10px 0 10px 0;
}

.scenicItem {
  width: 100%;
  margin: 0 0 0 0;
}

@font-face {
  font-family: 'SourceHan';
  src: url('../assets/fonts/SourceHanSansCN-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'CharacterSoul';
  src: url('../assets/fonts/字魂扁桃体.ttf') format('truetype');
}

.active2 {
  background: #DF6400 !important;
}

.el-input /deep/.el-input__suffix {
  height: 100%;
  top: 23px;
  right: -44px;
  transition: all .3s;
  pointer-events: none;
}


.nFlex {
  display: flex;
  align-items: center;
}

.el-icon-arrow-right {
  font-size: 16px;
  margin: 0 0 0 4px;
}

.nFont {
  font-size: 24px !important;
}

.newMask {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 25%;
  height: 850px;
  background: rgba(0, 0, 0, 0);
}

.newMask2 {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 25%;
  height: 850px;
  background: rgba(0, 0, 0, 0);
}

.contentRight {
  margin: 72px 0 0 0;
}

.contentRight2 {
  margin: 192px 0 0 0;
}

.viewMore {
  background: #ffffff !important;
  color: #FF7E14 !important;
}

.viewMore:hover {
  background: #ff7e14 !important;
  color: #ffffff !important;
}

.activeIndex {
  background: #ff7e14 !important;
}

.leftBtn:hover {
  opacity: 1;
}

.rightBtn:hover {
  opacity: 1;
}

.enAttract {
  font-size: 36px !important;
}
</style>
